const oahuJobsList = [
  {
    title: "Co-Pilot (Deckhand/Narrator)",
    description:
      "Position is responsible for delivering educational and entertaining marine narrations onboard our submarine and transport vessel while also functioning as a crew member. This is an entry level position on moving vessels in the ocean off Waikiki. Previous boating and SCUBA experience is helpful, but not necessary. A passion for excellent guest service is desirable. The full-time flexible schedule will include weekends and holidays. Pay rate is $18 per hour.",
  },
  {
    title: "Part-time Boat Captain",
    description:
      "Atlantis Cruises has an immediate opportunity for a part-time Boat Captain with a 100-ton USCG license to work as a captain on our beautiful cocktail cruise vessel The Majestic at Pier 6 in Honolulu. Pay rate is $30 per hour. Must have experience as a captain for a 100-ton or larger vessel. Previous tourism/hospitality experience desirable. Fun job, fun environment, and excellent benefits!",
  },
  {
    title: "Marine Maintenance Technician",
    description:
      "Position requires strong mechanical ability and very good aptitude with tools. Work schedule is 5pm - 3am, 4 nights on followed by 3 nights off. Work location is Pier 27 in Honolulu (703 N. Nimitz Highway). Pay rate will be $25-$26 per hour.",
  },
  {
    title: "Majestic Senior Captain",
    description:
      "Atlantis Cruises has an immediate need for a full-time Senior Captain who will oversee all operations of our vessel, Majestic. Position will report to our Majestic General Manager. Must have a 100-ton USCG license. A TWIC card is desirable. The schedule will include evening hours, weekends, and holidays. We need someone who is professional, courteous, safety conscious, and comfortable working on a moving vessel. Previous ocean and boating experience is required. We also need someone who is a skilled and effective leader and who can competently manage all operations of the Majestic. Full-time salary of $79,000 per year.",
  },
  {
    title: "Guest Service Agent",
    description:
      "Our ideal candidate is a strong and energetic team player who enjoys continuous guest interaction, is kind to both guests and other staff, and has the ability to work in a fast-paced environment while maintaining a calm and friendly attitude. Previous sales experience and computer proficiency required. Our ideal candidate will also have previous leadership experience and be able to motivate and inspire our guest services team, someone with the potential to become a future supervisor. Responsibilities include reservations, guest check-in, and payment and voucher processing. This is a full-time position and will include weekends and holidays. Pay rate is $16 per hour.",
  },
  // {
  //   title:
  //     "Part-time/On-Call Servers and Bartenders for Cocktail Cruises on the Majestic",
  //   description:
  //     "This Honolulu position involves significant guest interaction and service during whale watch cruises, cocktail cruises, and charters. Must be comfortable working on a boat. Will train entry level Bartenders. A passion for excellent guest service is important. The part-time/on-call schedule may include afternoons and evenings. Must be available on weekends, especially Fridays and Saturdays. Atlantis is regulated by the US Coast Guard, so employees must successfully complete DOT drug tests. Pay range is $16-$18 per hour.",
  // },
];

export default oahuJobsList;
