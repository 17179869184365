import banner from "../images/mauiBanner.jpg";
import pic from "../images/mauiAdventure.jpg";
import slide1 from "../images/mauiSlide1.jpg";
import slide2 from "../images/mauiSlide2.jpg";
import slide3 from "../images/mauiSlide3.jpg";
import slide4 from "../images/mauiSlide4.jpg";
import Slides from "../components/Slides";
import SubsCard from "../components/SubsCard";
import { Helmet } from "react-helmet";
import IslandTopMessage from "../components/IslandTopMessage";

function Maui() {
  return (
    <>
      <Helmet>
        <title>Atlantis Submarine Adventures | Maui</title>
      </Helmet>
      <IslandTopMessage
        banner={banner}
        island="Maui"
        message="Aloha. Our Atlantis Submarines Maui tours are temporarily closed due to the tragic Maui wildfires. We will post updates to this page as we are able to assess damage. If you have urgent questions please call 1-808-667-2224."
        heading="Dive 100 feet below the surface and experience Maui's marine
                mysteries."
        desc="Atlantis Submarines Maui is proud to be recognized as one of the
              largest providers of tourist attractions on the Hawaiian Islands.
              Our spectacular island setting plays host to our flagship tourist
              offering — the Maui Undersea Adventure. Featuring the most
              technologically advanced vessels of their kind, this absolutely
              real submarine carry up to 48 passengers to depths of 100 feet and
              beyond."
        youtube="https://youtu.be/w4ipckGcci4"
      />

      <Slides slide1={slide1} slide2={slide2} slide3={slide3} slide4={slide4} />

      <div className="container-fluid subsBG pt-20 pb-30">
        <SubsCard
          href="http://atlantisadventures.rezgo.com/details/151037/atlantis-submarine-tour-maui"
          pic={pic}
          alt="maui"
          title="MAUI UNDERSEA ADVENTURE"
          desc="Marvel at Maui's unique underwater paradise aboard a high-tech
              48-passenger submarine, as featured in National Geographic
              television specials. Feast your eyes on natural coral reefs, fish,
              and other marine life in air-conditioned comfort and safety. And
              now, get more out of your adventure with our newly-added
              artificial reef!"
          disable="yes"
        />
      </div>
    </>
  );
}

export default Maui;
