import { Helmet } from "react-helmet";

function Mission() {
  return (
    <>
      <Helmet>
        <title>Atlantis Submarine Adventures | Our Mission</title>
      </Helmet>
      <div className="whiteBG pt-50 pb-50">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8">
              <h5>
                <strong>Eco-Friendly | Educational | Exciting</strong>
              </h5>
              <p>
                <em>Aloha from Atlantis Adventures!</em>
                <br />
                <br />
                When you join us for a tour you see and experience Hawaii in a
                way that's offered by no one else in the Hawaiian Islands.
                <br />
                <br />
                You take away memories for life and a stronger appreciation for
                protecting our precious ocean environment, a message Atlantis
                imparts to every guest, every day.
              </p>
              <h5>
                <strong>Atlantis Submarines</strong>
              </h5>
              <p>
                With Atlantis Submarines, you explore the depths of Hawaii's
                ocean waters 100 feet below the surface and "swim" amidst
                schools of beautiful fish in the world's most technologically
                advanced passenger submarine.
                <br />
                <br />
                Atlantis has been sharing the magnificence of Hawaii's undersea
                world since 1988 using environmentally safe submarines that are
                battery powered, emit no pollutants, and quietly move through
                the water, disturbing no one. It's the ultimate in sustainable
                tourism.
                <br />
                <br />
                At our Waikiki and Maui dive sites, we've taken on the
                responsibility of installing artificial reefs to create
                self-sustaining habitats for fish and marine life to flourish.
                Areas once barren of life are now vibrant.
                <br />
                <br />
                Our Kona dive site is highlighted by a pristine 25-acre coral
                reef garden that is a natural safe haven for fish, plant life
                and organisms to thrive. Atlantis closely observes the abundance
                of activity at this treasured site but always leaves it
                untouched.
              </p>

              <h5>
                <strong>Majestic by Atlantis Cruises</strong>
              </h5>
              <p>
                Onboard Majestic by Atlantis Cruises, guests enjoy Hawaii's
                newest yacht cruise experience. Majestic offers the latest
                advancement in boat stabilization technology called Seakeeper,
                which ensures a more comfortable cruising experience.
                <br />
                <br />
                Other appealing features of Majestic include two classy, but
                expansive air-conditioned decks lined with panoramic windows and
                a huge open-air top deck for viewing the outdoors. With its
                sleek, modern design, Majestic is Atlantis's newest
                state-of-the-art cruise vessel, offering daily sunset dinner and
                appetizer cruises.
                <br />
                <br />
                Atlantis Cruises also offers seasonal whale watch cruises
                providing guests with insightful information about whales'
                behavior and biology through an on-board naturalist. Our mission
                is to educate guests about humpback whales and encourage support
                and protection of these gentle giants.
              </p>
              <h5>
                <strong>Hawaiian Values of Atlantis</strong>
              </h5>
              <p>
                The people of Atlantis share a commonality in perpetuating the
                sustainability of our ocean environment. This unity of voice and
                spirit is guided by the tenets of these seven Hawaiian Values:
              </p>
              <ul>
                <li>
                  Aloha Spirit - Treat people with dignity, respect, and
                  kindness
                </li>
                <li>ʻOhana - Family</li>
                <li>Kuleana - Responsibility</li>
                <li>Laulima - Cooperation</li>
                <li>ʻIke - Recognition, correct kindly</li>
                <li>Hoʻoponopono - Set things right</li>
                <li>
                  L<span style={{ fontSize: "1rem" }}>ō</span>
                  kahi - Peace, harmony, and unity
                </li>
              </ul>
              <p>
                Our employees live the promise, promote the message, and share
                the vision of these Hawaiian Values with each and every guest,
                each and every day. You will be welcomed as if you were entering
                our home and joining our Atlantis ʻohana (family).
                <br />
                <br />
                We carry the responsibility of Hawaiʻi's State Motto, Ua Mau Ke
                Ea O Ka ʻ
                <span style={{ fontFamily: "sans-serif", fontSize: "1rem" }}>
                  Ā
                </span>
                ina I Ka Pono (The Life of the Land is Perpetuated in
                Righteousness), and apply it with respect and honor to our work
                and our future in the ocean waters of Hawaiʻi Nei.
                <br />
                <br />
                <em>Mahalo nui loa!</em>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Mission;
