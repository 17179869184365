import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Maui from "./pages/Maui";
import Kona from "./pages/Kona";
import Submarines from "./pages/Submarines";
import Privacy from "./pages/Privacy";
import About from "./pages/About";
import Mission from "./pages/Mission";
import Contact from "./pages/Contact";
import Travel from "./pages/Travel";
import "./App.css";
import Sales from "./pages/Sales";
import Agency from "./pages/Agency";
import Jobs from "./pages/Jobs";
import Narration from "./pages/Narration";
import Kamaaina from "./pages/Kamaaina";
import Error from "./pages/Error";
import Waikiki from "./pages/Waikiki";
import Survey from "./pages/Survey";
import SubFacts from "./pages/SubFacts";
import Contest from "./pages/ContestRules";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/submarines" element={<Submarines />} />
        <Route path="/waikiki" element={<Waikiki />} />
        <Route path="/maui" element={<Maui />} />
        <Route path="/kona" element={<Kona />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/about" element={<About />} />
        <Route path="/our-mission" element={<Mission />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/travel-agent" element={<Travel />} />
        <Route path="/sales-packets" element={<Sales />} />
        <Route path="/agency" element={<Agency />} />
        <Route path="/employment" element={<Jobs />} />
        <Route path="/narration" element={<Narration />} />
        <Route path="/kamaaina" element={<Kamaaina />} />
        <Route path="/survey" element={<Survey />} />
        <Route path="/submarine-facts" element={<SubFacts />} />
        <Route path="/contestrules" element={<Contest />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
