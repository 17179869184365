import Button from "react-bootstrap/Button";

export default function KamaainaBook({
  pic,
  alt,
  title,
  desc,
  strikeAdult,
  strikeKeiki,
  priceAdult,
  priceKeiki,
  specialAdult,
  specialKeiki,
  href,
}) {
  function handleClick() {
    alert(
      "Please bring Hawaii driver's license, Hawaii state ID, or Military ID to check-in. Mahalo!"
    );
  }
  return (
    <div className="row justify-content-center pt-30">
      <div className="col-11 col-md-6 col-lg-4">
        <a onClick={handleClick} href={href}>
          <img src={pic} alt={alt} className="fullWidthImg pb-20" />
        </a>
      </div>
      <div className="col-12 col-md-6 col-lg-6 pb-20">
        <h2 className="majesticFont">{title}</h2>
        <p>
          {desc}
          <br />
          <br />
          {specialAdult ? (
            <div>
              <h5 className="mt-2 mb-1 text-primary">
                Whale Watch Kamaaina ticket sale runs from December 26 to
                January 5
              </h5>
              Adult:{" "}
              <span style={{ color: "orange", textDecoration: "line-through" }}>
                {strikeAdult}
              </span>{" "}
              {specialAdult} | Keiki:{" "}
              <span style={{ color: "orange", textDecoration: "line-through" }}>
                {strikeKeiki}
              </span>{" "}
              {specialKeiki} | Kids (2 and under): Free
              <br />
            </div>
          ) : (
            <div>
              Adult:{" "}
              <span style={{ color: "orange", textDecoration: "line-through" }}>
                {strikeAdult}
              </span>{" "}
              {priceAdult} | Keiki:{" "}
              <span style={{ color: "orange", textDecoration: "line-through" }}>
                {strikeKeiki}
              </span>{" "}
              {priceKeiki} | Kids (2 and under): Free
              <br />
            </div>
          )}
          *Plus tax and harbor fee*
        </p>
        <Button onClick={handleClick} className="bookNow" href={href}>
          <em>Join Us</em>
        </Button>
      </div>
    </div>
  );
}
