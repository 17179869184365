import oahuJobsList from "../components/oahuJobs";
// import mauiJobsList from "../components/mauiJobs";
import konaJobsList from "../components/konaJobs";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Button from "react-bootstrap/Button";
import top from "../images/joinOurTeam.jpg";
import { Helmet } from "react-helmet";
import JobsCard from "../components/JobsCard";
import { FaRegWindowClose } from "react-icons/fa";

function Jobs() {
  const form = useRef();
  const attach = useRef();

  function removeAttach() {
    attach.current.value = "";
  }

  function addJob(e) {
    setJob(e);
    setErrorJob("formInput");
    document.documentElement.scrollTo(0, 0);
  }

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [job, setJob] = useState("");
  const [about, setAbout] = useState("");
  const [errorName, setErrorName] = useState("formInput");
  const [errorEmail, setErrorEmail] = useState("formInput");
  const [errorPhone, setErrorPhone] = useState("formInput");
  const [errorJob, setErrorJob] = useState("formInput");
  const [errorAbout, setErrorAbout] = useState("formInput");
  const [waitMessage, setWaitMessage] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    if (!name) {
      setErrorName("errorFormInput");
    } else setErrorName("formInput");
    if (!email) {
      setErrorEmail("errorFormInput");
    } else setErrorEmail("formInput");
    if (!phone) {
      setErrorPhone("errorFormInput");
    } else setErrorPhone("formInput");
    if (!job) {
      setErrorJob("errorFormInput");
    } else setErrorJob("formInput");
    if (!about) {
      setErrorAbout("errorFormInput");
    } else setErrorAbout("formInput");

    if (name && email && phone && job && about) {
      setWaitMessage("Please wait...");

      emailjs
        .sendForm(
          "service_8kmbiu2",
          "template_i4ur4x9",
          form.current,
          "lIvuQcS29W-1LzLJc"
        )
        .then(
          function (response) {
            setName("");
            setEmail("");
            setPhone("");
            setJob("");
            setAbout("");
            removeAttach();
            setWaitMessage("Your message has been sent.");
            console.log("SUCCESS!", response.status, response.text);
          },
          function (err) {
            console.log("FAILED...", err);
            setWaitMessage(
              "There was an error. Please reload the page and try again"
            );
          }
        );
    } else {
      setWaitMessage("Required field(s) missing. Please check form.");
    }
  };

  return (
    <>
      <Helmet>
        <title>Atlantis Submarine Adventures | Employment</title>
      </Helmet>
      <div className="flowerBG pb-50">
        <div className="topSubs" style={{ textAlign: "right" }}>
          <img src={top} style={{ width: "100%" }} alt="top img" />
          <div className="subsIslands jobWidth">
            <h1 className="joinTeamText">
              <strong>
                <em>Join Our Team</em>
              </strong>
            </h1>
          </div>
        </div>
        <div className="container-lg pt-50">
          <div className="row justify-content-evenly">
            <div className="col-12 order-2 col-md-6 order-md-1 col-lg-6">
              <div>
                <h4 style={{ color: "#0088bb" }}>
                  <strong>Atlantis Employment Opportunities in Hawaii</strong>
                </h4>
                <p>
                  Atlantis has the following full-time opportunities available
                  on Oahu, Maui, and on the Big Island of Hawaii. Our benefits
                  include health and dental insurance, life insurance, long-term
                  disability insurance, holiday pay, paid personal leave, and a
                  401(k) Plan.
                </p>
                <p>
                  You may apply on our website (please attach a copy of your
                  resume) or send your resume to{" "}
                  <a href="mailto:hr@atlantisadventures.com">
                    hr@atlantisadventures.com
                  </a>
                  .
                </p>
                <p>
                  Please note that work authorization for the USA is required.
                  Please also note that we are unable to assist with relocation
                  to Hawaii.
                </p>
                <p>
                  If you are looking for an exciting career opportunity in the
                  travel and tourism industry, love to work with people, and are
                  fascinated by working under the sea, we invite you to apply
                  for employment with us.
                </p>
                <p className="mb-0">
                  For behind the scenes videos and more information about
                  working on the sea, please visit our Atlantis Adventures{" "}
                  <a href="https://facebook.com/AtlantisHawaii/">Facebook</a>{" "}
                  and{" "}
                  <a href="https://instagram.com/atlantishawaii/">Instagram</a>{" "}
                  pages.
                </p>
              </div>
              <JobsCard place="Oahu" list={oahuJobsList} addJob={addJob} />
              {/* <JobsCard place="Maui" list={mauiJobsList} addJob={addJob} /> */}
              <JobsCard place="Kona" list={konaJobsList} addJob={addJob} />
            </div>
            <div className="col-12 order-1 col-md-6 order-md-2 col-lg-5 center pb-50 ">
              <h3 className="jobFormHeader">
                <strong>Employment Contact Form</strong>
              </h3>
              <div className="jobForm">
                <form ref={form} id="jobsForm" onSubmit={onSubmit}>
                  <div className="container-fluid">
                    <div className="row justify-content-center pt-20">
                      <div className="col-6">
                        <input
                          className={errorName}
                          value={name}
                          name="name"
                          type="text"
                          placeholder="Full Name"
                          onChange={(e) => {
                            setName(e.target.value);
                            setErrorName("formInput");
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <input
                          className={errorEmail}
                          value={email}
                          name="email"
                          type="email"
                          placeholder="Email Address"
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setErrorEmail("formInput");
                          }}
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <input
                          className={errorPhone}
                          value={phone}
                          name="phone"
                          type="text"
                          placeholder="Phone Number"
                          onChange={(e) => {
                            setPhone(e.target.value);
                            setErrorPhone("formInput");
                          }}
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <select
                          className={errorJob}
                          value={job}
                          name="job"
                          onChange={(e) => {
                            setJob(e.target.value);
                            setErrorJob("formInput");
                          }}
                        >
                          <option value="" disabled>
                            Job Inquiring About
                          </option>
                          {oahuJobsList.map((job) => {
                            return newFunction(job, "Oahu");
                          })}
                          {/* {mauiJobsList.map((job) => {
                            return newFunction(job, "Maui");
                          })} */}
                          {konaJobsList.map((job) => {
                            return newFunction(job, "Kona");
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <textarea
                          rows="8"
                          className={errorAbout}
                          value={about}
                          name="about"
                          placeholder="About Yourself"
                          onChange={(e) => {
                            setAbout(e.target.value);
                            setErrorAbout("formInput");
                          }}
                        />
                      </div>
                    </div>
                    <div className="row pb-20">
                      <p className="text-start fw-bold">
                        Please attach your resume
                      </p>

                      <div className="row" style={{ paddingBottom: "5px" }}>
                        <div className="attachmentField col-10">
                          <input
                            ref={attach}
                            type="file"
                            name="attach"
                            id="resume"
                          />
                        </div>
                        <div className="col-2 d-flex justify-content-start align-items-center">
                          <FaRegWindowClose
                            size="24px"
                            onClick={removeAttach}
                          />
                        </div>
                      </div>
                    </div>
                    {waitMessage ? (
                      <p className="waitMessage">
                        <em>{waitMessage}</em>
                      </p>
                    ) : (
                      ""
                    )}
                    <Button
                      className="jobFormButton"
                      variant="dark"
                      type="submit"
                    >
                      Send
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  function newFunction(job, jobIsland) {
    return (
      <option key={job.title} value={`${jobIsland}: ${job.title}`}>
        {jobIsland}: {job.title}
      </option>
    );
  }
}

export default Jobs;
