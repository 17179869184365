const konaJobsList = [
  {
    title: "Co-Pilot (Deckhand/Narrator)",
    description:
      "Position is responsible for delivering educational and entertaining marine narrations onboard our submarine and transport vessel while also functioning as a crew member. This entry level position helps prepare for entry into our Submersible Pilot training program. Previous boating and SCUBA experience is helpful. A passion for excellent guest service is desirable. The full-time flexible schedule will include weekends and holidays. Pay rate is $20 per hour.",
  },
  {
    title: "Maintenance Technician",
    description:
      "Position requires experience in electronics or diesel engine repair, as well as electrical, mechanical, hydraulics or pneumatic systems experience. SCUBA certification is required. Work schedule will generally be 4 pm - 12 midnight, but may vary and will include some weekends and holidays. Pay range is $25-$30 per hour.",
  },
  {
    title: "Guest Service Agent",
    description:
      "Our ideal candidate is a strong and energetic team player who enjoys continuous guest interaction, is kind to both guests and other staff, and has the ability to work in a fast-paced environment while maintaining a calm and friendly attitude. Previous sales experience and computer proficiency required. Responsibilities include reservations, guest check-in, and payment and voucher processing. This is a full-time position and will include weekends and holidays. Beautiful work environment! Pay rate is $20 per hour.",
  },
];

export default konaJobsList;
