import Button from "react-bootstrap/Button";
import usa from "../images/usa-48.png";
import korea from "../images/korea-48.png";
import china from "../images/china-48.png";
import mexico from "../images/mexico-48.png";
import japan from "../images/japan-48.png";

function NarrationCard({
  en,
  downloadEn,
  jp,
  downloadJp,
  kr,
  downloadKr,
  ch,
  downloadCh,
  sp,
  downloadSp,
  pic,
  island,
}) {
  return (
    <div className="row justify-content-center pt-30">
      <div className="col-11 col-md-8 col-lg-6">
        <img className="fullWidthImg pb-20" src={pic} alt={island} />
      </div>
      <div className="col-10 col-md-4 col-lg-6">
        {en ? (
          <Button
            className="narrationBtn"
            variant="light"
            size="lg"
            href={en}
            download={downloadEn}
          >
            <img src={usa} alt="usa flag" /> English
          </Button>
        ) : (
          ""
        )}
        {jp ? (
          <Button
            className="narrationBtn"
            variant="light"
            size="lg"
            href={jp}
            download={downloadJp}
          >
            <img src={japan} alt="japan flag" /> Japanese
          </Button>
        ) : (
          ""
        )}
        {kr ? (
          <Button
            className="narrationBtn"
            variant="light"
            size="lg"
            href={kr}
            download={downloadKr}
          >
            <img src={korea} alt="korea flag" /> Korean
          </Button>
        ) : (
          ""
        )}
        {ch ? (
          <Button
            className="narrationBtn"
            variant="light"
            size="lg"
            href={ch}
            download={downloadCh}
          >
            <img src={china} alt="china flag" /> Mandarin
          </Button>
        ) : (
          ""
        )}
        {sp ? (
          <Button
            className="narrationBtn"
            variant="light"
            size="lg"
            href={sp}
            download={downloadSp}
          >
            <img src={mexico} alt="mexico flag" /> Spanish
          </Button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default NarrationCard;
