import banner from "../images/submarinesBanner.jpg";
import subs from "../images/subsIslands.png";
import waikiki from "../images/waikikiWithIcon.jpg";
import maui from "../images/mauiWithIcon.jpg";
import kona from "../images/konaWithIcon.jpg";
import IslandTop from "../components/IslandTop";
import SubsCard from "../components/SubsCard";
import { Helmet } from "react-helmet";

function Submarines() {
  return (
    <>
      <Helmet>
        <title>Atlantis Submarine Adventures | Hawaii</title>
      </Helmet>
      <IslandTop
        banner={banner}
        center={subs}
        island="HAWAII"
        desc="Atlantis Submarines has been sharing the magnificence of Hawaii's
              undersea world since 1988 using the environmentally safe
              submarines that are battery powered, emit no pollutants, and
              quietly move through the water, disturbing no one. It's the
              ultimate in sustainable tourism. At our Waikiki and Maui dive
              sites, we've taken on the responsibility of installing artificial
              reefs to create self-sustaining habitats for fish and marine life
              to flourish. Areas once barren of life are now vibrant. Our Kona
              dive site is highlighted by a pristine 25-acre coral reef garden
              that is a natural safe haven for fish, plant life and organisms to
              thrive. Atlantis closely observes the abundance of activity at
              this treasured site but always leaves it untouched."
        youtube="https://youtu.be/XMB3vnZwEp4"
      />
      <div className="container-fluid subsBG pt-20 pb-30">
        <SubsCard
          linkPage="/waikiki"
          pic={waikiki}
          alt="waikiki"
          title="WAIKIKI UNDERSEA ADVENTURE"
          desc="Add deeper meaning to your island visit by diving over 100 feet in an
          authentic submarine. Our state-of-the-art submarines are Coast Guard
          approved and air-conditioned for your safety and comfort. Located
          within view of famous Leahi (Diamond Head), the Atlantis dive site is
          frequented by green turtles, sharks, stingrays, yellow tangs, eels,
          and many other species of underwater marine life."
          href="https://atlantisadventures.rezgo.com/details/151625/atlantis-submarine-tour-in-waikiki"
        />

        <SubsCard
          linkPage="/maui"
          pic={maui}
          alt="maui"
          title="MAUI UNDERSEA ADVENTURE"
          desc="Atlantis Submarines Maui is proud to be recognized as one of the
              largest providers of tourist attractions on the Hawaiian Islands.
              Our spectacular island setting plays host to our flagship offering
              — the Atlantis Undersea Adventure. Featuring the most
              technologically advanced vessels of their kind, these absolutely
              real submarines carry up to 48 passengers to depths of 100 feet
              and beyond."
          href="http://atlantisadventures.rezgo.com/details/151037/atlantis-submarine-tour-maui"
        />

        <SubsCard
          linkPage="/kona"
          pic={kona}
          alt="kona"
          title="KONA UNDERSEA ADVENTURE"
          desc="Allow Atlantis Submarines to show you the other 96% of Kona you
              can't see any other way. Beneath the surface of the ocean, you'll
              have the chance to see Kona's marine mysteries including 25 acres
              of stunning natural reef and all of its many inhabitants. Join us
              for one of Kona's most unique oceanic adventures!"
          href="http://atlantisadventures.rezgo.com/details/154563/atlantis-submarine-tour-kona"
        />
      </div>
    </>
  );
}

export default Submarines;
