import ReactPlayer from "react-player/youtube";

export default function IslandTopMessage({
  banner,
  message,
  island,
  heading,
  desc,
  youtube,
}) {
  return (
    <>
      <div className="topSubs">
        <img className="homeBanner" src={banner} alt="home banner" />
        <div
          className="position-absolute top-0 start-50 translate-middle-x bg-danger rounded shadow mt-3 border border-secondary"
          style={{ zIndex: "100000", width: "90%" }}
        >
          <h1 className="text-light mb-0 py-3 px-4 lh-base">{message}</h1>
        </div>
      </div>
      {desc ? (
        <div className="container-fluid homeSection1 pt-50">
          <div className="row justify-content-center pb-50">
            <div className="col-12 col-md-5 col-lg-4">
              <h3>EXPLORE OUR {island}</h3>
              <p>
                {heading ? (
                  <>
                    <strong>{heading}</strong>
                    <br />
                  </>
                ) : (
                  ""
                )}

                {desc}
              </p>
            </div>
            <div className="col-12 col-md-7 col-lg-5">
              <div className="player-wrapper">
                <ReactPlayer
                  className="react-player"
                  url={youtube}
                  playing={false}
                  width="100%"
                  height="100%"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
