import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

export default function SubsCard({
  linkPage,
  pic,
  alt,
  title,
  desc,
  href,
  disable,
}) {
  return (
    <div className="row justify-content-center pt-30">
      <div className="col-11 col-md-6 col-lg-5">
        {linkPage ? (
          <Link to={linkPage}>
            <img src={pic} className="fullWidthImg pb-20" alt={alt} />
          </Link>
        ) : (
          <a href={href}>
            <img src={pic} className="fullWidthImg pb-20" alt={alt} />
          </a>
        )}
      </div>
      <div className="col-12 col-md-6 col-lg-4 pb-20">
        <h3>{title}</h3>
        <p style={{ color: "#fff" }}>{desc}</p>
        {disable ? (
          <Button href={href} className="bookNow disabled">
            Book Now
          </Button>
        ) : (
          <Button href={href} className="bookNow">
            Book Now
          </Button>
        )}
      </div>
    </div>
  );
}
