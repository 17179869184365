import cruises from "../images/cruisesButton.png";
import subs from "../images/subsButton.png";
import { Link } from "react-router-dom";
import homeBanner from "../images/home.jpg";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <div>
      <Helmet>
        <title>
          Atlantis Submarine Adventures Hawaii | Majestic by Atlantis Cruises
        </title>
      </Helmet>
      <div className="topSubs">
        <img className="homeBanner" src={homeBanner} alt="home banner" />
        <div className="homeButtons center">
          <div className="homeButton1">
            <a
              href="https://majestichawaii.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={cruises} className="cruisesButton" alt="cruises" />
            </a>
          </div>
          <div className="homeButton2">
            <Link to="/submarines">
              <img src={subs} className="subsButton" alt="subs" />
            </Link>
          </div>
        </div>
      </div>
      <div className="container-fluid homeSection1 pt-50">
        <div className="row justify-content-md-center">
          <div className="col-6 col-md-5 col-lg-4">
            <h3>Majestic by Atlantis Cruises</h3>
            <p>
              <a
                href="https://majestichawaii.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Majestic by Atlantis Cruises
              </a>{" "}
              introduces Hawaii's next generation of cruise vessels to enjoy
              sunset cocktail cruises, Friday fireworks & cocktail cruises, and
              other special events in the waters off Oahu. The vessel's name,
              Majestic, truly matches its character and the famous waters it
              cruises.
              <br />
              <br />
              The 150-foot Majestic is Atlantis's newest state-of-the-art cruise
              vessel. It sports a sleek, yacht-like design, offering a multitude
              of options to host couples, families, tour groups, and private
              functions. More specifically, it features two elegant, spacious
              air-conditioned decks lined with panoramic windows and high
              ceilings, a huge open-air top deck for viewing outdoors, and
              cutting-edge stabilization technology to ensure a comfortable
              ocean cruise.
            </p>
          </div>
          <div className="col-6 col-md-5 col-lg-4">
            <h3>Atlantis Submarines</h3>
            <p className="pb-50">
              With <Link to="/submarines">Atlantis Submarines</Link>, you
              explore depths of Hawaii's ocean waters 100 feet below the surface
              and "swim" amidst schools of beautiful fish in the world's most
              technologically advanced passenger submarine.
              <br />
              <br />
              Atlantis has been sharing the magnificence of Hawaii's undersea
              world since 1988 using the environmentally safe submarines that
              are battery powered, emit no pollutants, and quietly move through
              the water, disturbing no one. It's the ultimate in sustainable
              tourism.
              <br />
              <br />
              At our Waikiki and Maui dive sites, we've taken on the
              responsibility of installing artificial reefs to create
              self-sustaining habitats for fish and marine life to flourish.
              Areas once barren of life are now vibrant.
              <br />
              <br />
              Our Kona dive site is highlighted by a pristine 25-acre coral reef
              garden that is a natural safe haven for fish, plant life and
              organisms to thrive. Atlantis closely observes the abundance of
              activity at this treasured site but always leaves it untouched.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
