import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { Link, useLocation } from "react-router-dom";
import logo from "../images/atlantis-logo.png";
import Button from "react-bootstrap/Button";
import { useState, useEffect } from "react";
import BookNow from "./BookNow";
import usaFlag from "../images/usa-48.png";
import japanFlag from "../images/japan-48.png";
import { AiFillCaretDown } from "react-icons/ai";
import { AiFillCaretUp } from "react-icons/ai";

function Header() {
  const location = useLocation();
  const [languageMenu, setLanguageMenu] = useState(false);
  const [buttonBorder, setButtonBorder] = useState("borderRounded");

  function languageButtonClick() {
    setLanguageMenu(!languageMenu);
    buttonBorder === "borderRounded"
      ? setButtonBorder("borderSquare")
      : setButtonBorder("borderRounded");
  }

  let bookNowURL = "";
  if (location.pathname.includes("waikiki")) {
    bookNowURL =
      "https://atlantisadventures.rezgo.com/details/151625/atlantis-submarine-tour-in-waikiki-booknow";
  }
  if (location.pathname.includes("maui")) {
    bookNowURL =
      "https://atlantisadventures.rezgo.com/details/151037/atlantis-submarine-tour-maui-booknow";
  }
  if (location.pathname.includes("kona")) {
    bookNowURL =
      "https://atlantisadventures.rezgo.com/details/154563/atlantis-submarine-tour-kona-booknow";
  }
  const [expanded, setExpanded] = useState(false);
  const [book, setBook] = useState("bookHide");
  function clickLink() {
    setBook("bookHide");
  }
  function handleClick() {
    setExpanded(false);
    setBook(book === "bookHide" ? "bookShow" : "bookHide");
    setLanguageMenu(false);
    setButtonBorder("borderRounded");
  }

  useEffect(() => {
    const handleGlobalClick = (event) => {
      // Assume your "Book Now" button or dropdown has a specific class name, adjust the selector as needed
      const isDropdownOrButtonClicked = event.target.matches(
        ".bookNow, .bookNow *"
      );
      if (!isDropdownOrButtonClicked && book === "bookShow") {
        setBook("bookHide");
      }
      // Additional logic to close the language menu if clicked outside, similar to your existing languageMenu logic
      if (!event.target.closest(".languageButton") && languageMenu) {
        setLanguageMenu(false);
        setButtonBorder("borderRounded");
      }
    };

    // Add click event listener to the whole document
    document.addEventListener("click", handleGlobalClick);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleGlobalClick);
    };
  }, [book, languageMenu]); // Dependencies for useEffect

  return (
    <>
      <BookNow book={book} click={clickLink} />
      <Navbar
        expanded={expanded}
        collapseOnSelect
        bg="primary"
        variant="dark"
        sticky="top"
        expand="xl"
      >
        <Container fluid>
          <div>
            <Navbar.Brand
              as={Link}
              onClick={() => {
                setExpanded(false);
                setBook("bookHide");
                setLanguageMenu(false);
                setButtonBorder("borderRounded");
              }}
              to="/"
            >
              <img src={logo} className="navLogo" alt="logo" />
            </Navbar.Brand>
            {location.pathname.includes("kamaaina") |
            location.pathname.includes("maui") |
            location.pathname.includes("travel-agent") |
            location.pathname.includes("sales-packets") |
            location.pathname.includes("agency") ? (
              ""
            ) : (
              <Nav id="bookNowDropdown2">
                {bookNowURL ? (
                  <Button className="bookNow text-nowrap" href={bookNowURL}>
                    Book Now
                  </Button>
                ) : (
                  <Button className="bookNow text-nowrap" onClick={handleClick}>
                    Book Now
                  </Button>
                )}
              </Nav>
            )}
          </div>
          <Navbar.Toggle
            onClick={() => {
              setExpanded(expanded ? false : "expanded");
              setBook("bookHide");
            }}
            aria-controls="responsive-navbar-nav"
          />
          <Navbar.Collapse id="responsive-navbar-nav" className="text-center">
            <Nav className="me-auto">
              <Nav.Link
                as={Link}
                onClick={() => {
                  setExpanded(false);
                  setBook("bookHide");
                }}
                eventKey="1"
                to="/submarines"
              >
                Submarines
              </Nav.Link>
              <Nav.Link
                as={Link}
                onClick={() => {
                  setExpanded(false);
                  setBook("bookHide");
                }}
                eventKey="2"
                to="/waikiki"
              >
                Waikiki
              </Nav.Link>
              <Nav.Link
                as={Link}
                onClick={() => {
                  setExpanded(false);
                  setBook("bookHide");
                }}
                eventKey="3"
                to="/maui"
              >
                Maui
              </Nav.Link>
              <Nav.Link
                as={Link}
                onClick={() => {
                  setExpanded(false);
                  setBook("bookHide");
                }}
                eventKey="4"
                to="/kona"
              >
                Kona
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  setExpanded(false);
                  setBook("bookHide");
                }}
                href="https://majestichawaii.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Majestic by Atlantis Cruises
              </Nav.Link>
              <Nav.Link
                as={Link}
                onClick={() => {
                  setExpanded(false);
                  setBook("bookHide");
                }}
                eventKey="5"
                to="/submarine-facts"
              >
                Submarine Facts
              </Nav.Link>
            </Nav>

            {location.pathname.includes("kamaaina") |
            location.pathname.includes("maui") |
            location.pathname.includes("travel-agent") |
            location.pathname.includes("sales-packets") |
            location.pathname.includes("agency") ? (
              ""
            ) : (
              <Nav id="bookNowDropdown">
                {bookNowURL ? (
                  <Button className="bookNow text-nowrap" href={bookNowURL}>
                    Book Now
                  </Button>
                ) : (
                  <Button className="bookNow text-nowrap" onClick={handleClick}>
                    Book Now
                  </Button>
                )}
              </Nav>
            )}
            <div className="position-relative">
              <Button
                variant="light"
                size="sm"
                className={`languageButton text-nowrap shadow ms-xl-3 mt-3 mt-xl-0 rounded-top ${buttonBorder}`}
                onClick={languageButtonClick}
              >
                <img src={usaFlag} alt="usa flag" style={{ width: "25px" }} />{" "}
                English{" "}
                {buttonBorder === "borderRounded" ? (
                  <AiFillCaretDown />
                ) : (
                  <AiFillCaretUp />
                )}
              </Button>

              {languageMenu && (
                <Button
                  variant="light"
                  size="sm"
                  className="languageButton2 text-nowrap shadow ms-lg-3 mt-3 mt-lg-0 rounded-bottom"
                  href="https://jp.atlantisadventures.com"
                >
                  <img
                    src={japanFlag}
                    alt="japan flag"
                    style={{ width: "25px" }}
                  />{" "}
                  日本語
                </Button>
              )}
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
