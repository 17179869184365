import Carousel from "react-bootstrap/Carousel";

function Slides({ slide1, slide2, slide3, slide4 }) {
  return (
    <>
      <Carousel indicators={false} pause={false}>
        <Carousel.Item>
          <img className="d-block w-100" src={slide1} alt="slide 1" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={slide2} alt="slide 2" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={slide3} alt="slide 3" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={slide4} alt="slide 4" />
        </Carousel.Item>
      </Carousel>
    </>
  );
}

export default Slides;
