import IslandTop from "../components/IslandTop";
import banner from "../images/konaBanner.jpg";
import pic from "../images/konaAdventure.jpg";
import slide1 from "../images/konaSlide1.jpg";
import slide2 from "../images/konaSlide2.jpg";
import slide3 from "../images/konaSlide3.jpg";
import slide4 from "../images/konaSlide4.jpg";
import Slides from "../components/Slides";
import SubsCard from "../components/SubsCard";
import { Helmet } from "react-helmet";

function Kona() {
  return (
    <>
      <Helmet>
        <title>Atlantis Submarine Adventures | Kona</title>
      </Helmet>
      <IslandTop
        banner={banner}
        island="KONA"
        heading="Dive 100 feet below the surface and experience Kona's marine
                mysteries."
        desc="Allow Atlantis Submarines to show you the other 96% of Kona you
              can't see any other way. Treat yourself to Kona's most beautiful
              and captivating scenery, habitats, and isolated treasures."
        youtube="https://youtu.be/fjAPbTak_AM"
      />

      <Slides slide1={slide1} slide2={slide2} slide3={slide3} slide4={slide4} />

      <div className="container-fluid subsBG pt-20 pb-30">
        <SubsCard
          href="http://atlantisadventures.rezgo.com/details/154563/atlantis-submarine-tour-kona"
          pic={pic}
          alt="kona"
          title="KONA UNDERSEA ADVENTURE"
          desc="An unforgettable 45-minute journey aboard an Atlantis 48-passenger
              submarine, as featured in National Geographic television specials,
              where guests explore a 25-acre natural coral reef and its marine
              inhabitants. A new adventure awaits you at depths up to 100 feet!
              Enjoy the view in air-conditioned comfort and safety."
        />
      </div>
    </>
  );
}

export default Kona;
