import dennis from "../images/Dennis-Hurd.jpg";
import { Helmet } from "react-helmet";

function About() {
  return (
    <>
      <Helmet>
        <title>Atlantis Submarine Adventures | About Us</title>
      </Helmet>
      <div className="whiteBG pt-50 pb-50">
        <div className="container">
          <div className="row justify-content-evenly">
            <div className="col-12 col-md-7 col-lg-6">
              <h5>
                <strong>The Story of Atlantis Submarines</strong>
              </h5>
              <p>
                Dennis Hurd is the president of Atlantis Submarines
                International, Inc., the company responsible for taking more
                than 11 million passengers on undersea adventures aboard its 48
                and 64 passenger submarines. A fleet larger than that of many
                national navies, Atlantis subs are now found in Grand Cayman,
                Barbados, St. Thomas, Aruba, Guam, Cozumel, and on the Hawaii
                Islands of Hawaii, Maui and Oahu.
              </p>
              <p>
                The Atlantis XIV, which operates off Waikiki Beach, Hawaii,
                accommodates 64 passengers, spans more than 100 feet and is the
                world's largest passenger submarine. Hurd launched his first
                battery-powered, nonpolluting Atlantis Submarine in December
                1985 off the coast of the Cayman Islands. The high-tech nature
                of the vessel, the air-conditioned, pressure-controlled cabin,
                and the underwater beauty of the reef made the attraction an
                instant hit. A design engineer who once captained a 20-ton
                schooner from Nova Scotia to the Bahamas, Hurd came up with the
                idea of recreational submarines during his tenure at
                International Hydrodynamics ("Hyco").
              </p>
              <p>
                At the time, Hyco was the world leader in deep-sea manned
                submersibles and the developer of submersibles that could tend
                North Sea oil rigs to depths of 6,600 feet. In the 1970's, Hurd
                would often take clients down in these submarines to inspect
                drilling sites. The thrill these executives got from subsea
                exploration was enough to make him think seriously about
                underwater tourism. During the slow winter months of 1977, Hurd
                studied the possibility of taking small submersibles into the
                Bahamas, but quickly discovered that a vehicle with capacity far
                more than one or two passengers would be a more financially
                viable option. From 1979-1983, Hurd ran his own worldwide
                service company, Offshore Engineering Corporation, which
                operated the small oil-industry subs. In 1983, he used the
                profits from that successful venture to start what is now the
                Atlantis Submarines organization.
              </p>
              <p>
                Beginning with $250,000 in design and development money from
                friends and business acquaintances, Hurd completed preliminary
                drawings and market studies. He then went on to raise the
                millions necessary to build Atlantis I. Atlantis I was based on
                15 years prior experience in the research and commercial
                submersible industry including extensive experience in both the
                design and operational aspects of the technology. Atlantis I was
                purpose built to carry 28 passenger on hour long trips to tour
                coral reefs in tropical resort locations — a way for all people
                to experience the underwater world previously the reserve of
                scuba divers. Atlantis I would dive to 150 feet in air
                conditioned comfort and with no pressure on the passengers.
                Precise maneuvering would tour guests close to the reefs, viewed
                from large windows which were customer designed, built and
                tested by Atlantis.
              </p>
              <p>
                In 1986, Atlantis I began the world's first tourist submarine
                operation in Grand Cayman. It was highly successful and
                attracted world wide attention. Essentially a prototype,
                improvements were made in the early stages and incorporated into
                Atlantis II, which was displayed at the world exposition,
                Expo86, in Vancouver. The Atlantis II journeyed directly from
                the expo and reached Barbados in 1986. The official opening was
                held on February 1987.
              </p>
              <p>
                The design and operational experience gained with Atlantis I and
                II led to the development of Atlantis III, a much larger, 48
                passenger sub, which was launched in 1987 in St. Thomas, US
                Virgin Islands. This was the first submarine to be certified by
                the US Coast Guard. Atlantis started the School Children's
                Program; classroom work is followed by a dive, which provides
                children with real exposure to the ocean environment, and an
                awareness of its fragile nature.
              </p>
              <p>
                Subsequent 48 passenger submarines were launched in Kona, Maui,
                and Oahu on the Hawaiian Islands, and in Guam, Aruba, and Mexico
                during this period. In 1994, a 64 passenger submarine was
                designed and built for Oahu in Hawaii. At the time, Oahu has two
                operating subs, but the large market demand led to this larger
                third submarine. Of particular interest in Oahu is the
                underwater submarine adventure. Atlantis built and deployed a
                series of reef structures aimed at attracting and protecting
                numerous different marine life into an area that was previously
                scoured by a hurricane many years ago, removing the protective
                coral cover for marine life.
              </p>
              <p>
                In November 1998, Atlantis expanded its operations beyond the
                submarine tours into semi-submersible and island tours.
                Semi-subs provide passengers with a view of the ocean from large
                windows placed opposite seats located below the ocean surface —
                however, the semi-submarine does not submerge and dive.
                Excellent viewing is had in shallow water reefs. Today,
                semi-submarines (also know as Seaworld Explorer) are operated by
                Atlantis in Grand Cayman, St. Martin, Aruba, and Curacao. During
                this period Atlantis further expanded into other tours and
                currently provides catamaran sailing, snorkel and a variety of
                adventures in all its island operations.
              </p>
              <p>
                Hurd has taken his idea through the whole spectrum of challenges
                that are involved in designing, financing, and building a unique
                vessel, having it approved by regulatory authorities and
                insured, moving it into production, developing operating
                systems, maintenance systems and training programs, and
                establishing operating sites around the globe.
              </p>
              <p>
                Last year the organization employed approximately 450 people,
                operating 11 tourist submarines and several other tours at 11
                locations around the world, and carried almost 1 million
                passengers. Among Atlantis' passengers are over 40,000 local
                school children, whose classes participated in the company's
                "Living Classroom" program.
              </p>
              <p>Quite a story!</p>
            </div>
            <div
              className="col-11 col-md-5 col-lg-4"
              style={{
                padding: "10px",
                border: "2px solid #bbb",
                borderRadius: "5px",
                backgroundColor: "#fff",
              }}
            >
              <img src={dennis} alt="dennis" className="pb-20" />
              <h5>
                <strong>Dennis Hurd</strong>
              </h5>
              <h5>
                <strong>President / CEO / Founder Atlantis Adventures</strong>
              </h5>
              <p>
                1985
                <br />
                Dennis Hurd and a group of investors draw up plans for Atlantis
                I, the world's first public-passenger submarine.
              </p>
              <p>
                1986
                <br />
                Atlantis I is put into service and entertains her first
                passengers off the coast of the Cayman Islands, British West
                Indies.
              </p>
              <p>
                1986
                <br />
                Atlantis II makes her public debut at EXPO 86 in Vancouver,
                British Columbia.
              </p>
              1987
              <br />
              <p>
                A larger Atlantis, the 48-passenger Atlantis III, goes into
                operation off the coast of St. Thomas, U.S. Virgin Islands. This
                submarine would become the US Coast Guard's benchmark for
                certifying public-passenger submarines in US territorial and
                coastal waters.
              </p>
              <p>
                1987
                <br />
                Operations are launched in Barbados featuring the 28-passenger
                Atlantis II submarine.
              </p>
              <p>
                1988
                <br />
                Atlantis Submarines gains worldwide media attention with
                National Geographic and “Good Morning America”. Operations are
                launched in Kona, Hawaii featuring the Atlantis IV and the
                Atlantis V on the island of Guam.
              </p>
              <p>
                1989
                <br />
                Operations in Oahu, Hawaii commence with the debut of the all
                new Atlantis VI.
              </p>
              <p>
                1990
                <br />
                Operations are launched in Aruba featuring the all new Atlantis
                VII.
              </p>
              <p>
                1991
                <br />
                Atlantis unveils the all new Atlantis IX and X vessels for use
                in the Hawaiian islands. Operations in Maui, Hawaii commence and
                operations in Honolulu become the first two-vessel operation at
                Atlantis Adventures.
              </p>
              <p>
                1992
                <br />
                The 20,000th child participates in the "Atlantis Living
                Classroom" experience.
              </p>
              <p>
                1994
                <br />
                Atlantis launches Mexico's first passenger submarine (Atlantis
                XII) off the coast of Cancun. The state of the art, 64-passenger
                Atlantis XIV debuts off the coast of Oahu, Hawaii.
              </p>
              <p>
                1998
                <br />
                Atlantis purchases the Shorex operation featuring a fleet of
                semi-submersibles and acquires 100% full ownership of operations
                in Aruba.
              </p>
              <p>
                1999
                <br />
                Atlantis' operations in Cancun, Mexico are relocated to the
                island of Cozumel.
              </p>
              <p>
                2000
                <br />
                Acquisition of Atlantis V.I. Adventures in St. Thomas and the
                expansion into the tour brokering business. Hawaii acquires
                Navatel I and launches the Atlantis Cruises tour.
              </p>
              <p>
                2003
                <br />
                Atlantis Adventures debuts new Rhino Rider Water Safari tours in
                Barbados.
              </p>
              <p>
                2003
                <br />
                Atlantis Adventures launches expanded website, unifying core
                operations and expanded online reservation capabilities.
              </p>
              <p>
                2004
                <br />
                Atlantis Adventures celebrates 15 years of operation on the
                Hawaiian Islands.
              </p>
              <p>
                2005
                <br />
                Atlantis Adventures reaches two milestones including 20 years of
                operation and more than 10 million passengers.
              </p>
              <p>
                2008
                <br />
                Atlantis Submarines St. Thomas closed, submarine moved to
                Barbados.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
