import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import banner from "../images/errorPage.jpg";
import { Helmet } from "react-helmet";

function Error() {
  return (
    <>
      <Helmet>
        <title>Atlantis Submarine Adventures | 404</title>
      </Helmet>
      <div className="topSubs">
        <img src={banner} className="fullWidthImg" alt="banner" />
        <div className="subsIslands center">
          <h1>
            <strong>
              <em>Page Not Found!</em>
            </strong>
          </h1>
          <Button as={Link} to="/" size="lg" variant="light">
            Home
          </Button>
        </div>
      </div>
    </>
  );
}

export default Error;
