import Button from "react-bootstrap/Button";
import top from "../images/kamaaina.jpg";
import products from "../images/products.jpg";
import cocktail from "../images/cocktailSquare.jpg";
import fireworks from "../images/fireworksSquare.jpg";
import whale from "../images/whaleSquare.jpg";
import KamaainaBook from "../components/KamaainaBook";
import { Helmet } from "react-helmet";

function Kamaaina() {
  function handleClick() {
    alert(
      "Please bring Hawaii driver's license, Hawaii state ID, or Military ID to check-in. Mahalo!"
    );
  }
  return (
    <div>
      <Helmet>
        <title>Atlantis Submarine Adventures | Kama'aina</title>
      </Helmet>
      {/* <div
        className="position-fixed vw-100 text-center ticker"
        style={{ backgroundColor: "#124789", zIndex: "100" }}
      >
        <p className="text-white fs-5 fst-italic lh-sm bannerText px-1 py-2 m-0">
          Explore Our New Year's Eve Cruise{" "}
          <a
            style={{ color: "#fff" }}
            href="https://atlantisadventures.rezgo.com/details/323407/majestic-new-years-eve-cruise"
          >
            Click for Details
          </a>
        </p>
      </div> */}
      <img
        className="fullWidthImg position-relative"
        src={top}
        alt="top img"
        // style={{ top: "36px" }}
      />
      <div className="subsBG pt-50 pb-50">
        <h1 className="pb-30 center">
          <em>Mahalo to our Kama'aina! Save on Ticket Discounts</em>
        </h1>

        <h2 className="pb-20 center kamaainaSubsTitle">Atlantis Submarines</h2>
        <div className="container-md">
          <div className="row justify-content-center">
            <div className="col-11 pb-20 col-md-6 col-lg-4">
              <img src={products} className="fullWidthImg" alt="products" />
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <p style={{ color: "#fff" }}>
                Less than 1% of the world's population has ever been deeper than
                100 feet below the surface of the ocean so we invite you to
                explore the Hawaii you may have never seen before. Add deeper
                meaning to your next adventure with the ohana by exploring in an
                authentic submarine. Our state-of-the-art vessels are coast
                guard approved and air-conditioned for your safety and comfort.
                <br />
                <br />
                Adult rate:{" "}
                <span
                  style={{ color: "orange", textDecoration: "line-through" }}
                >
                  $148
                </span>{" "}
                $111, Child rate:{" "}
                <span
                  style={{ color: "orange", textDecoration: "line-through" }}
                >
                  $66
                </span>{" "}
                $49.50
                <br />
                Tax and harbor fee added at checkout. Transportation not
                included.
                <br />
                *A valid Hawaii ID or Military ID is required at check-in*
              </p>
            </div>
          </div>
          <div className="row pt-20 justify-content-center center">
            <div className="col-4 col-md-3 col-lg-2">
              <Button
                onClick={handleClick}
                className="bookNow fullWidthImg"
                href="http://atlantisadventures.rezgo.com/details/205302/atlantis-premium-submarine-tour-waikiki-kamaaina-discount"
              >
                Waikiki Adventure
              </Button>
            </div>
            <div className="col-4 col-md-3 col-lg-2">
              <Button
                onClick={handleClick}
                className="bookNow fullWidthImg"
                href="http://atlantisadventures.rezgo.com/details/205329/atlantis-submarine-tour-maui-kamaaina-discount"
              >
                Maui Adventure
              </Button>
            </div>
            <div className="col-4 col-md-3 col-lg-2">
              <Button
                onClick={handleClick}
                className="bookNow fullWidthImg"
                href="http://atlantisadventures.rezgo.com/details/205340/atlantis-submarine-tour-kona-kamaaina-discount"
              >
                Kona Adventure
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="flowerBG pt-50 pb-30">
        <h1 className="center majesticFont">Majestic by Atlantis Cruises</h1>
        <div className="container-md">
          <KamaainaBook
            pic={cocktail}
            alt="cocktail cruise"
            title="Waikiki Sunset Cocktail Cruise"
            desc="Step out onto Majestic's open-air decks and take the opportunity
                to simply enjoy Hawaii's beautiful sunsets and skylines. The
                Majestic features two decks that offer the space to take in
                unobscured panoramic views and lovely breezes."
            strikeAdult="$79.00"
            strikeKeiki="$39.50"
            priceAdult="$39.50"
            priceKeiki="$19.75"
            href="http://atlantisadventures.rezgo.com/details/309586/waikiki-sunset-cocktail-cruise-kamaaina-discount"
          />

          <KamaainaBook
            pic={whale}
            alt="whale watch cruise"
            title="See Hawaii's Humpbacks! (December 26 to March 31)"
            desc="Each year, humpback whales return to the islands from frigid
                Alaska to breed, calve, and nurse their young. Come witness this
                amazing event for yourself on board the Majestic!"
            strikeAdult="$79.00"
            strikeKeiki="$39.50"
            priceAdult="$59.25"
            priceKeiki="$29.63"
            // specialAdult="$29.63"
            // specialKeiki="$14.82"
            href="http://atlantisadventures.rezgo.com/details/221534/whale-watch-cruise-in-waikiki-kamaaina-discount"
          />

          <KamaainaBook
            pic={fireworks}
            alt="fireworks cruise"
            title="Get a 25% Discount for All Kamaaina Tickets On Our Friday Fireworks & Cocktail Cruise"
            desc="Experience an unforgettable evening with Friday night fireworks
                and cocktails on the sea aboard Majestic by Atlantis Cruises.
                Majestic has Oahu's only sleek, modern, full-service bar and
                cocktail lounge on the sea where guests can savor handcrafted
                cocktails while cruising along Waikiki's famous coastline in
                air-conditioned comfort."
            strikeAdult="$79.00"
            strikeKeiki="$39.50"
            priceAdult="$59.25"
            priceKeiki="$29.63"
            href="https://atlantisadventures.rezgo.com/details/317222/friday-fireworks-cocktail-cruise-kamaaina-discount"
          />
        </div>
      </div>
    </div>
  );
}

export default Kamaaina;
