import Card from "react-bootstrap/Card";
import { FaMapMarkerAlt } from "react-icons/fa";

export default function ContactCard({
  title,
  phone,
  email,
  address1,
  address2,
  map1,
  map2,
  hours,
}) {
  return (
    <Card className="mb-30">
      <Card.Body>
        <h5>
          <strong>{title}</strong>
        </h5>
        <p>
          <strong>Phone:</strong>{" "}
          <a href="tel:1-800-381-0237">1-800-381-0237</a> or{" "}
          <a href={`tel:${phone}`}>{phone}</a>
        </p>
        <p>
          <strong>Email:</strong> <a href={`mailto:${email}`}>{email}</a>
        </p>
        <p>
          <strong>Address:</strong> {address1}
          <br />
          <a target="_blank" rel="noopener noreferrer" href={map1}>
            Google Map <FaMapMarkerAlt />
          </a>
        </p>
        {address2 && (
          <p>
            <strong>Address:</strong> {address2}
            <br />
            <a target="_blank" rel="noopener noreferrer" href={map2}>
              Google Map <FaMapMarkerAlt />
            </a>
          </p>
        )}

        <p style={{ marginBottom: "0" }}>
          <strong>Hours:</strong> {hours}
        </p>
      </Card.Body>
    </Card>
  );
}
