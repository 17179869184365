import { Helmet } from "react-helmet";
import top from "../images/travelAgentTop.jpg";

function Travel() {
  return (
    <>
      <Helmet>
        <title>Atlantis Submarine Adventures | Submarine Facts</title>
      </Helmet>
      <div className="whiteBG travel pb-50">
        <img
          src={top}
          style={{ width: "100%", paddingBottom: "50px" }}
          alt="travel bg"
        />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xxl-9">
              <h1
                className="fw-bold text-center pb-3"
                style={{ color: "#00276d" }}
              >
                SUBMARINE FACTS
              </h1>
              <ul className="mb-0 ps-3 factsList">
                <li>
                  Atlantis Submarines is a global company which has operated for
                  35 years without incident while serving over 17 million
                  passengers on over 500,000 submarine dives.
                </li>
                <li>
                  Atlantis Submarines Hawaii is Coast Guard certified. We
                  undergo regularly scheduled Coast Guard inspections. We are
                  also certified by the American Bureau of Shipping.
                </li>
                <li>
                  Atlantis Submarines Hawaii has a highly trained maintenance
                  team that inspects and maintains our submarines on each
                  island.
                </li>
                <li>
                  Our Atlantis Submarine pilots are Coast Guard licensed and
                  they also receive their recertification annually.
                </li>
                <li>
                  Our 48 passenger and 64 passenger air-conditioned submarines
                  dive up to a maximum of 150 feet. Our dive sites are located
                  off the coastlines of Waikiki, Lahaina, and Kona.
                </li>
                <li>
                  Atlantis Submarines operates dives for 45 minutes underwater
                  at specific dive locations with designated routes.
                </li>
                <li className="pb-0">
                  Each of our submarine dive sites has a surface support vessel
                  located close to our dive site to support our submarine tours.
                  This vessel is in constant communication with our submarine at
                  all times.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Travel;
