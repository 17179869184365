import ContactCard from "../components/ContactCard";
import { Helmet } from "react-helmet";

function Contact() {
  return (
    <>
      <Helmet>
        <title>Atlantis Submarine Adventures | Contact Us</title>
      </Helmet>
      <div className="whiteBG pt-50 pb-20">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-6">
              <h3 className="center pb-20">
                <strong>CONTACT US</strong>
              </h3>
              <ContactCard
                title="Atlantis Submarines Waikiki & Atlantis Cruises Waikiki"
                phone="808-973-9800"
                email="ores@atlantisadventures.com"
                address1="Atlantis Submarines Waikiki - 252 Paoa Pl, Honolulu, HI 96815"
                address2="Atlantis Cruises Waikiki - 301 Aloha Tower Drive, Pier 6"
                map1="https://goo.gl/maps/uAwty6y2JFq"
                map2="https://goo.gl/maps/Ytfw1fPfCdF2"
                hours="Open daily 7:30am-6pm"
              />
              <ContactCard
                title="Atlantis Submarines Maui"
                phone="808-667-2494"
                email="mres@atlantisadventures.com"
                address1="658 Wharf St #11, Lahaina, HI 96761"
                map1="https://goo.gl/maps/ud93zQRkFHCRY4Zt9"
                hours="Open daily 7:30am-6pm"
              />
              <ContactCard
                title="Atlantis Submarines Kona"
                phone="808-326-7827"
                email="kres@atlantisadventures.com"
                address1="75-5660 Palani Rd #304, Kailua-Kona, Hawaii 96740"
                map1="https://goo.gl/maps/Dqnr5adtywF2"
                hours="Open daily 8am-5pm"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
