import NarrationCard from "../components/NarrationCard";
import top from "../images/narrationTop.jpg";
import waikiki from "../images/waikikiWithIcon.jpg";
import maui from "../images/mauiWithIcon.jpg";
import kona from "../images/konaWithIcon.jpg";
import waikikiEnglish from "../audio/waikikiEnglish.mp3";
import waikikiJapanese from "../audio/waikikiJapanese.mp3";
import waikikiKorean from "../audio/waikikiKorean.mp3";
import waikikiMandarin from "../audio/waikikiMandarin.mp3";
import waikikiSpanish from "../audio/waikikiSpanish.mp3";
import mauiJapanese from "../audio/mauiJapanese.mp3";
import mauiKorean from "../audio/mauiKorean.mp3";
import mauiMandarin from "../audio/mauiMandarin.mp3";
import mauiSpanish from "../audio/mauiSpanish.mp3";
import konaEnglish from "../audio/konaEnglish.mp3";
import konaSpanish from "../audio/konaSpanish.mp3";
import konaMandarin from "../audio/konaMandarin.mp3";
import konaJapanese from "../audio/konaJapanese.mp3";
import konaKorean from "../audio/konaKorean.mp3";
import { Helmet } from "react-helmet";
import IslandTop from "../components/IslandTop";

function Narration() {
  return (
    <>
      <Helmet>
        <title>Atlantis Submarine Adventures | Narration</title>
      </Helmet>
      <div className="whiteBG">
        <IslandTop banner={top} island="Narration" />
        <div className="container-md pt-50 pb-50">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8">
              <strong>ABOUT TOUR DOWNLOADS</strong>
              <p>
                Learn about Hawaii's marine environment with Atlantis
                Submarines. Choose from one of the selections listed below and
                simply download our tour narration in your preferred language to
                your phone or tablet.
              </p>

              <p>
                Be sure to download your audio tour before your submarine
                adventure since there is no Wi-Fi as we dive over 100 feet
                beneath the sea. Mahalo for joining us on a journey to the ocean
                floor. Don't forget your earbuds or headphones!
              </p>
              <div className="row justify-content-between">
                <div className="col-5">
                  <strong className="languages">LANGUAGES AVAILABLE:</strong>
                  <ul style={{ marginBottom: "0" }} className="flagList">
                    <li>ENGLISH</li>
                    <li>JAPANESE</li>
                    <li>KOREAN</li>
                    <li>MANDARIN</li>
                    <li>SPANISH</li>
                  </ul>
                </div>
                <div className="col-7 col-lg-5 align-self-end headphones-box">
                  <p>
                    <strong>
                      DON'T FORGET TO BRING YOUR
                      <br />
                      EARBUDS OR HEADPHONES!
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="subsBG pb-30 pt-20">
          <div className="container-md">
            <NarrationCard
              en={waikikiEnglish}
              downloadEn="waikikiEnglish.mp3"
              jp={waikikiJapanese}
              downloadJp="waikikiJapanese.mp3"
              kr={waikikiKorean}
              downloadKr="waikikiKorean.mp3"
              ch={waikikiMandarin}
              downloadCh="waikikiMandarin.mp3"
              sp={waikikiSpanish}
              downloadSp="waikikiSpanish.mp3"
              pic={waikiki}
              island="Waikiki"
            />
            <hr />
            <NarrationCard
              jp={mauiJapanese}
              downloadJp="mauiJapanese.mp3"
              kr={mauiKorean}
              downloadKr="mauiKorean.mp3"
              ch={mauiMandarin}
              downloadCh="mauiMandarin.mp3"
              sp={mauiSpanish}
              downloadSp="mauiSpanish.mp3"
              pic={maui}
              island="Maui"
            />
            <hr />
            <NarrationCard
              en={konaEnglish}
              downloadEn="konaEnglish.mp3"
              jp={konaJapanese}
              downloadJp="konaJapanese.mp3"
              kr={konaKorean}
              downloadKr="konaKorean.mp3"
              ch={konaMandarin}
              downloadCh="konaMandarin.mp3"
              sp={konaSpanish}
              downloadSp="konaSpanish.mp3"
              pic={kona}
              island="Kona"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Narration;
