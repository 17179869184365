import waikiki from "../images/agencyWaikiki.jpg";
import maui from "../images/agencyMaui.jpg";
import kona from "../images/agencyKona.jpg";
import majestic from "../images/agencyMajestic.jpg";
import SubsCard from "../components/SubsCard";
import { Helmet } from "react-helmet";

function Agency() {
  return (
    <>
      <Helmet>
        <title>Atlantis Submarine Adventures | Travel Agency Booking</title>
      </Helmet>
      <div className="fishBG pt-50 pb-30">
        <div className="container-fluid">
          <h1 className="center" style={{ color: "white" }}>
            ATLANTIS ADVENTURES TOUR HIGHLIGHTS
          </h1>
          <SubsCard
            pic={waikiki}
            alt="waikiki"
            title="WAIKIKI UNDERSEA ADVENTURE"
            desc="Add deeper meaning to your island visit by diving over 100 feet in an
          authentic submarine. Our state-of-the-art submarines are Coast Guard
          approved and air-conditioned for your safety and comfort. Located
          within view of famous Leahi (Diamond Head), the Atlantis dive site is
          frequented by green turtles, sharks, stingrays, yellow tangs, eels,
          and many other species of underwater marine life."
            href="https://atlantisadventures.rezgo.com/details/151625/atlantis-submarine-tour-in-waikiki/?promo=travelagent"
          />
          <hr />
          <SubsCard
            pic={majestic}
            alt="majestic"
            title="MAJESTIC BY ATLANTIS CRUISES"
            desc="Step out onto Majestic's open-air decks and take the opportunity
              to simply enjoy Hawaii's beautiful sunsets and skylines. The
              Majestic features two decks that offer the space to take in
              unobscured panoramic views and lovely breezes. Join us on our
              Waikiki Sunset Cocktail Cruise where we offer the only full
              service, open seating bar and lounge sailing Oahu's waters. Or
              check out our seasonal whale watch cruise to spot Hawaii's
              humpback whales as they breed, calve and nurse their young in the
              islands during the winter/spring months."
            href="https://atlantisadventures.rezgo.com/details/204130/waikiki-sunset-cocktail-cruise/?promo=travelagent"
          />
          <hr />
          <SubsCard
            pic={maui}
            alt="maui"
            title="MAUI UNDERSEA ADVENTURE"
            desc="Atlantis Submarines Maui is proud to be recognized as one of the
              largest providers of tourist attractions on the Hawaiian Islands.
              Our spectacular island setting plays host to our flagship offering
              — the Atlantis Undersea Adventure. Featuring the most
              technologically advanced vessels of their kind, these absolutely
              real submarines carry up to 48 passengers to depths of 100 feet
              and beyond."
            href="https://atlantisadventures.rezgo.com/details/151037/atlantis-submarine-tour-maui/?promo=travelagent"
          />
          <hr />
          <SubsCard
            pic={kona}
            alt="kona"
            title="KONA UNDERSEA ADVENTURE"
            desc="Allow Atlantis Submarines to show you the other 96% of Kona you
              can't see any other way. Beneath the surface of the ocean, you'll
              have the chance to see Kona's marine mysteries including 25 acres
              of stunning natural reef and all of its many inhabitants. Join us
              for one of Kona's most unique oceanic adventures!"
            href="http://atlantisadventures.rezgo.com/details/154563/atlantis-submarine-tour-kona?promo=travelagent"
          />
        </div>
      </div>
    </>
  );
}

export default Agency;
