import travelWaikiki from "../images/TravelAgentWAIKIKI.jpg";
import travelMajestic from "../images/TravelAgentMajestic.jpg";
// import travelMaui from "../images/TravelAgentMAUI.jpg";
import travelKona from "../images/TravelAgentKONA.jpg";
import margie from "../images/margie.png";
// import brenda from "../images/brenda.png";
import Agent from "../components/Agent";
import { Helmet } from "react-helmet";

function Sales() {
  return (
    <>
      <Helmet>
        <title>Atlantis Submarine Adventures | Sales Packets</title>
      </Helmet>
      <div className="fishBG pt-50 pb-50">
        <div className="container">
          <h1 className="center pb-50" style={{ color: "white" }}>
            ATLANTIS SALES TEAM
          </h1>
          <Agent
            profile={margie}
            pic={travelWaikiki}
            pic2={travelMajestic}
            name="MARGIE LEHMAN"
            title="Director of Oahu Sales"
            phone="808-228-4155"
            email="mlehman@atlantisadventures.com"
            island="Oahu"
            link="https://drive.google.com/folderview?id=0B59kuuu0LZ90cHVya0pqSkQzaDA&resourcekey=0-8Dly3JKHsA4MY6038DMEeg"
          />
          <hr />
          {/* <Agent
            profile={brenda}
            pic={travelMaui}
            pic2=""
            name="BRENDA BAROSO"
            title="Maui Sales Manager"
            phone="808-667-6604"
            email="bbaroso@atlantisadventures.com"
            island="Maui"
            link="https://drive.google.com/folderview?id=0B59kuuu0LZ90VkJFLW5XRUtfTFE&resourcekey=0-gFo2SpPALDiOyojmP7vBPg"
          />
          <hr /> */}
          <Agent
            // profile={laura}
            pic={travelKona}
            pic2=""
            name="SHINTARO HIRANO"
            title="Kona Sales Manager"
            phone="808-329-3175"
            ext="0133"
            email="shirano@atlantisadventures.com"
            island="Kona"
            link="https://drive.google.com/folderview?id=0B59kuuu0LZ90bmU4ZVlVa3Vzcm8&resourcekey=0-jyIO5DeYVaVpHcXtXmVL7A"
          />
        </div>
      </div>
    </>
  );
}

export default Sales;
