function ContestRules() {
  return (
    <div className="container">
      <div className="row justify-content-center py-5">
        <div className="col-12 col-md-10 col-lg-8">
          <h1 className="mb-4 text-center">
            Valentine's Adventure Giveaway 2024
          </h1>
          <p>
            For the purposes of these Terms and Conditions, &quot;The
            Promoter&quot; refers to Atlantis Adventures whose Instagram handle
            is @atlantishawaii The &quot;Prize&quot; refers to one Valentine’s
            package for 2 adults which includes one weekend stay for 2 adults in
            a Hilton Hawaiian Village beachfront room. Two adult tickets on
            Atlantis Submarines Waikiki Submarine tour. Two adult tickets on
            Majestic by Atlantis Cruises Waikiki Sunset Cocktail Cruise
          </p>
          <ul className="contestList">
            <li>
              By entering the contest, you agree to be bound by these terms and
              conditions. All entries must be received by 11:59 pm HST 2/8/24.
              One (1) winner will be selected at random by The Promoter on
              2/9/24. The winner will be notified via Instagram direct message
              on or after this date.
            </li>
            <li>
              The winner must respond by 2/23/24 that they accept the
              Valentine’s prize package. This Valentine’s package must be
              redeemed on or before 2/22/25
            </li>
            <li>
              No purchase necessary. Winners will not be required to pay to
              enter the competition.
            </li>
            <li>
              Entrants must be over 18 years old on the date of their entry.
              Proof of age may be required.
            </li>
            <li>Employees of The Promoter are not eligible to enter.</li>
            <li>
              Instagram or Facebook are not in any way affiliated or involved in
              the competition.
            </li>
            <li>Only one entry per person per competition will be accepted.</li>
            <li>
              Each entrant shall enter the competition via Instagram and
              following these instructions: follow @atlantisadventures, like
              this photo, tag a friend in the comments below
            </li>
            <li>
              The Prize will be awarded to a randomly selected winner who has
              entered on Instagram. The Promoter will not be held liable if the
              named prize becomes unavailable or cannot be fulfilled.
            </li>
            <li>
              The Promoter will not be held liable for any failure of receipt of
              entries. The Promoter takes no responsibility for any entries
              which are lost, delayed, illegible, corrupted, damaged, incomplete
              or otherwise invalid.
            </li>
            <li>
              To the extent permitted by applicable law, The Promoter’s
              liability under or in connection with the competition or these
              terms and conditions shall be limited to the cost price of the
              Prize in question.
            </li>
            <li>
              To the extent permitted by applicable law, The Promoter shall not
              be liable under or in connection with these terms and conditions,
              the competition or any Prize for any indirect, special or
              consequential cost, expense, loss or damage suffered by a
              participant even if such cost, expense, loss or damage was
              reasonably foreseeable or might reasonably have been contemplated
              by the participant and the promoter and whether arising from
              breach of contract, tort, negligence, breach of statutory duty or
              otherwise.
            </li>
            <li>
              Prizes are non-negotiable, non-transferable and non-refundable. No
              cash alternative is available. Where a Prize becomes unavailable
              for any reason, the promoter reserves the right to substitute that
              prize for a prize of equal or higher value.
            </li>
            <li>
              The name, address, email address and phone number of the winner
              must be provided to The Promoter if requested and will be shared
              to enable fulfilment of the Prize.
            </li>
            <li>
              In the event of unforeseen circumstances beyond The Promoter’s
              reasonable control, the promoter reserves the right to cancel,
              terminate, modify or suspend the competition or these terms and
              conditions, either in whole or in part, with or without notice.
            </li>
            <li>
              The Promoter&#39;s decision is final. No correspondence will be
              entered into.
            </li>
            <li>
              The winner’s name and social media username may be posted on the
              social media profiles of The Promotor after the winner has been
              selected.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ContestRules;
