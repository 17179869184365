import IslandTop from "../components/IslandTop";
import banner from "../images/waikikiBanner.jpg";
import pic from "../images/waikikiAdventure.jpg";
import slide1 from "../images/waikikiSlide1.jpg";
import slide2 from "../images/waikikiSlide2.jpg";
import slide3 from "../images/waikikiSlide3.jpg";
import slide4 from "../images/waikikiSlide4.jpg";
import Slides from "../components/Slides";
import SubsCard from "../components/SubsCard";
import { Helmet } from "react-helmet";

function Waikiki() {
  return (
    <>
      <Helmet>
        <title>Atlantis Submarine Adventures | Waikiki</title>
      </Helmet>
      <IslandTop
        banner={banner}
        island="WAIKIKI"
        heading="Dive 100 feet below the surface and experience Waikiki's marine mysteries."
        desc="Our dive site is located within view of iconic Leahi (Diamond Head) and is frequented by green sea turtles, sharks, yellow tangs, eels, and many other species of underwater marine life. See firsthand how sunken ships, remnants of hurricane-swept airliners, pyramid structures, and other objects have combined with Mother Nature to attract life to the Atlantis reef."
        youtube="https://youtu.be/XMB3vnZwEp4"
      />

      <Slides slide1={slide1} slide2={slide2} slide3={slide3} slide4={slide4} />

      <div className="container-fluid subsBG pt-20 pb-30">
        <SubsCard
          href="https://atlantisadventures.rezgo.com/details/151625/atlantis-submarine-tour-in-waikiki"
          pic={pic}
          alt="waikiki"
          title="WAIKIKI UNDERSEA ADVENTURE"
          desc="Experience a unique oceanic adventure in the world's largest recreational submarine and descend to 100 feet beneath the surface of the ocean. Watch the drama of nature unfold as we bring you up close and personal to Hawaii's marine wonders! With 64 seats and large portholes, our Submarine adventure is a must-have experience."
        />
      </div>
    </>
  );
}

export default Waikiki;
