import ReactPlayer from "react-player/youtube";

export default function IslandTop({
  banner,
  center,
  island,
  heading,
  desc,
  youtube,
}) {
  return (
    <>
      <div className="topSubs">
        <img className="homeBanner" src={banner} alt="home banner" />
        <div className="subsIslands">
          {center ? <img src={center} alt="center" /> : <>{island}</>}
        </div>
      </div>
      {desc ? (
        <div className="container-fluid homeSection1 pt-50">
          <div className="row justify-content-center pb-50">
            <div className="col-12 col-md-5 col-lg-4">
              <h3>EXPLORE OUR {island}</h3>
              <p>
                {heading ? (
                  <>
                    <strong>{heading}</strong>
                    <br />
                  </>
                ) : (
                  ""
                )}

                {desc}
              </p>
            </div>
            <div className="col-12 col-md-7 col-lg-5">
              <div className="player-wrapper">
                <ReactPlayer
                  className="react-player"
                  url={youtube}
                  playing={false}
                  width="100%"
                  height="100%"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
