import Button from "react-bootstrap/Button";

export default function Agent({
  profile,
  pic,
  pic2,
  name,
  phone,
  email,
  title,
  link,
  island,
  ext,
}) {
  return (
    <div className="row justify-content-center center">
      <div className="col-10 col-md-6 order-2 order-md-1 fullIMG">
        <img src={pic} alt="travel waikiki" />
        {pic2 ? (
          <img src={pic2} style={{ marginTop: "20px" }} alt="travel majestic" />
        ) : (
          ""
        )}
      </div>
      <div className="col-10 col-md-6 col-lg-3 order-1 order-md-2">
        <img src={profile} alt=" " />
        <h4>
          <strong>{name}</strong>
        </h4>
        <p style={{ color: "#fff" }}>{title}</p>
        <p>
          <strong>
            <a className="phone" href={`tel:${phone}`}>
              Tel: {phone} {ext ? <>ext {ext}</> : ""}
            </a>
          </strong>
        </p>
        <p>
          <a className="phone" href={`mailto:${email}`}>
            {email}
          </a>
        </p>
        <Button
          style={{ marginBottom: "20px" }}
          variant="outline-light"
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          Download {island} Sales Packets (Fact Sheets, Images, Videos)
        </Button>
      </div>
    </div>
  );
}
