import { useState, useRef } from "react";
import { Helmet } from "react-helmet";
import emailjs from "@emailjs/browser";
import Button from "react-bootstrap/Button";

export default function Survey() {
  const form = useRef();

  const [tour, setTour] = useState("");
  const [referral, setReferral] = useState("");
  const [referralOther, setReferralOther] = useState("");
  const [arrival, setArrival] = useState("");
  const [time, setTime] = useState("");

  const [errorTour, setErrorTour] = useState("formInput");
  const [errorReferral, setErrorReferral] = useState("formInput");
  const [errorArrival, setErrorArrival] = useState("formInput");
  const [errorTime, setErrorTime] = useState("formInput");

  const [waitMessage, setWaitMessage] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    if (!tour) {
      setErrorTour("errorFormInput");
    } else setErrorTour("formInput");
    if (!referral) {
      setErrorReferral("errorFormInput");
    } else setErrorReferral("formInput");
    if (!arrival) {
      setErrorArrival("errorFormInput");
    } else setErrorArrival("formInput");
    if (!time) {
      setErrorTime("errorFormInput");
    } else setErrorTime("formInput");

    if (tour && referral && arrival && time) {
      setWaitMessage("Please wait...");

      emailjs
        .sendForm(
          "service_u548ub8",
          "template_n2r217p",
          form.current,
          "lIvuQcS29W-1LzLJc"
        )
        .then(
          function (response) {
            setTour("");
            setReferral("");
            setArrival("");
            setTime("");
            setWaitMessage("Thank you for your responses!");
            console.log("SUCCESS!", response.status, response.text);
          },
          function (err) {
            console.log("FAILED...", err);
            setWaitMessage(
              "There was an error. Please reload the page and try again"
            );
          }
        );
    } else {
      setWaitMessage("Required field(s) missing. Please check form.");
    }
  };

  return (
    <>
      <Helmet>
        <title>Atlantis Adventures | Survey</title>
      </Helmet>
      <div className="whiteBG">
        <div className="container-fluid">
          <div className="row center justify-content-center">
            <div className="col-lg-3 col-md-4 col-11 pb-50">
              <div>
                <h3 className="pt-50 pb-20">CUSTOMER SURVEY</h3>
                <form ref={form} id="surveyForm" onSubmit={onSubmit}>
                  <div className="row">
                    <div className="col-12">
                      <select
                        className={errorTour}
                        value={tour}
                        name="tour"
                        onChange={(e) => {
                          setTour(e.target.value);
                          setErrorTour("formInput");
                        }}
                      >
                        <option value="" disabled>
                          Which tour did you take?
                        </option>
                        <option value="Atlantis Submarines Waikiki">
                          Atlantis Submarines Waikiki
                        </option>
                        <option value="Atlantis Submarines Maui">
                          Atlantis Submarines Maui
                        </option>
                        <option value="Atlantis Submarines Kona">
                          Atlantis Submarines Kona
                        </option>
                        <option value="Majestic by Atlantis Cruises Waikiki Sunset Cocktail Cruise">
                          Majestic by Atlantis Cruises Waikiki Sunset Cocktail
                          Cruise
                        </option>
                        <option value="Majestic by Atlantis Cruises Friday Fireworks & Cocktail Cruise">
                          Majestic by Atlantis Cruises Friday Fireworks &
                          Cocktail Cruise
                        </option>
                        <option value="Majestic by Atlantis Cruises Whale Watch Cruise">
                          Majestic by Atlantis Cruises Whale Watch Cruise
                        </option>
                      </select>
                    </div>
                    <div className="col-12">
                      <select
                        className={errorReferral}
                        value={referral}
                        name="referral"
                        onChange={(e) => {
                          setReferral(e.target.value);
                          setErrorReferral("formInput");
                        }}
                      >
                        <option value="" disabled>
                          How did you hear about us?
                        </option>
                        <option value="Google">Google</option>
                        <option value="Social Media">Social Media</option>
                        <option value="Yelp">Yelp</option>
                        <option value="YouTube">YouTube</option>
                        <option value="Viator, Expedia, Trip Advisor, or other online travel agency">
                          Viator, Expedia, Trip Advisor, or other online travel
                          agency
                        </option>
                        <option value="Airport Video">Airport Video</option>
                        <option value="Friends or Family">
                          Friends or Family
                        </option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div className="col-12">
                      {referral === "Other" ? (
                        <input
                          type="text"
                          placeholder="Please enter how you heard about us"
                          className="formInput"
                          value={referralOther}
                          name="referralOther"
                          onChange={(e) => {
                            setReferralOther(e.target.value);
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-12">
                      <select
                        className={errorArrival}
                        value={arrival}
                        name="arrival"
                        onChange={(e) => {
                          setArrival(e.target.value);
                          setErrorArrival("formInput");
                        }}
                      >
                        <option value="" disabled>
                          Did you book your tour before arriving in Hawaii?
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                    <div className="col-12">
                      <select
                        className={errorTime}
                        value={time}
                        name="time"
                        onChange={(e) => {
                          setTime(e.target.value);
                          setErrorTime("formInput");
                        }}
                      >
                        <option value="" disabled>
                          How far in advance did you book your tour?
                        </option>
                        <option value="1-3 days before tour">
                          1-3 days before tour
                        </option>
                        <option value="4-7 days before tour">
                          4-7 days before tour
                        </option>
                        <option value="8-11 days before tour">
                          8-11 days before tour
                        </option>
                        <option value="9-13 days before tour">
                          9-13 days before tour
                        </option>
                        <option value="14+ days before tour">
                          14+ days before tour
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="pt-10 center">
                    {waitMessage ? (
                      <p className="waitMessage">
                        <em>{waitMessage}</em>
                      </p>
                    ) : (
                      ""
                    )}
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
