export default function BookNowCard({ href, pic, alt, text, click }) {
  return (
    <div className="topSubs col-4 col-md-3 col-lg-2 dropdownItem">
      <a onClick={click} className="dropdownLink" href={href}>
        <img className="fullWidthImg" src={pic} alt={alt} />
        <div className="bookText">{text}</div>
      </a>
    </div>
  );
}
