import waikikiBook from "../images/waikikiBook.jpg";
import mauiBook from "../images/mauiBook.jpg";
import konaBook from "../images/konaBook.jpg";
import cocktailBook from "../images/cocktailBook.jpg";
import fireworksBook from "../images/fireworksBook.jpg";
import whaleBook from "../images/whaleBook.jpg";
import BookNowCard from "./BookNowCard";

export default function BookNow({ book, click }) {
  return (
    <div className={`chooseBook ${book}`}>
      <div className="container-lg">
        <div className="row justify-content-center center">
          <BookNowCard
            href="https://atlantisadventures.rezgo.com/details/151625/atlantis-submarine-tour-in-waikiki-dropdown"
            pic={waikikiBook}
            alt="book waikiki"
            text="Waikiki Underwater Adventure"
            click={click}
          />
          <BookNowCard
            href="https://atlantisadventures.rezgo.com/details/151037/atlantis-submarine-tour-maui-dropdown"
            pic={mauiBook}
            alt="book maui"
            text="Maui Underwater Adventure"
            click={click}
          />
          <BookNowCard
            href="https://atlantisadventures.rezgo.com/details/154563/atlantis-submarine-tour-kona-dropdown"
            pic={konaBook}
            alt="book kona"
            text="Kona Underwater Adventure"
            click={click}
          />
        </div>
        <div className="row justify-content-center center">
          <BookNowCard
            href="https://atlantisadventures.rezgo.com/details/204130/waikiki-sunset-cocktail-cruise-dropdown"
            pic={cocktailBook}
            alt="book cocktail cruise"
            text="Waikiki Sunset Cocktail Cruise"
            click={click}
          />
          <BookNowCard
            href="https://atlantisadventures.rezgo.com/details/205347/friday-fireworks-cocktail-cruise-dropdown"
            pic={fireworksBook}
            alt="book fireworks"
            text="Friday Fireworks Cruise"
            click={click}
          />
          <BookNowCard
            href="https://atlantisadventures.rezgo.com/details/154748/whale-watch-cruise-in-waikiki-dropdown"
            pic={whaleBook}
            alt="book whale watch"
            text="Whale Watch Cruise in Waikiki"
            click={click}
          />
        </div>
      </div>
    </div>
  );
}
