import { FaArrowRight } from "react-icons/fa";

export default function JobsCard({ list, place, addJob }) {
  if (list.length === 0) {
    return (
      <div className="jobs pt-30">
        <h4 style={{ color: "#0088bb" }}>
          <strong>{place}</strong>
        </h4>
        <p className="mb-0">No jobs available</p>
      </div>
    );
  }

  return (
    <div className="jobs pt-30">
      <h4 style={{ color: "#0088bb" }}>
        <strong>{place}</strong>
      </h4>
      {list.map((job, i, row) => {
        if (i === row.length - 1) {
          return (
            <div key={job.title}>
              {newFunction(job, addJob, place)}
              <p className="mb-0">{job.description}</p>
            </div>
          );
        } else {
          return (
            <div key={job.title}>
              {newFunction(job, addJob, place)}
              <p>{job.description}</p>
            </div>
          );
        }
      })}
    </div>
  );

  function newFunction(job, addJob, place) {
    return (
      <h5>
        <strong>{job.title} </strong>{" "}
        <div style={{ fontSize: "1.1rem", display: "inline-block" }}>
          <FaArrowRight />{" "}
          <a
            href="#0"
            onClick={() => {
              addJob(`${place}: ${job.title}`);
            }}
          >
            Add to Form
          </a>
        </div>
      </h5>
    );
  }
}
