import logo from "../images/atlantis-logo.png";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import hea from "../images/hea.png";
import years from "../images/35years.jpg";
import steward from "../images/stewardship.jpg";

function Footer() {
  return (
    <>
      <div className="container p-0 m-0 mw-100 d-flex justify-content-center">
        <div className="row justify-content-evenly py-3 w-100">
          <div className="col-12 col-md-6 d-flex justify-content-center pb-4 pb-md-0 px-0">
            <img src={years} alt="35 years" className="years" />
          </div>
          <div className="col-12 col-md-6 justify-content-center justify-content-md-start d-flex align-items-center px-0">
            <img src={steward} alt="stewardship" className="stewardSize" />
          </div>
        </div>
      </div>
      <div className="container-fluid footer pt-50">
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <Link className="footerLogo" to="/">
              <img src={logo} alt="atlantis logo" />
            </Link>
            <a className="footerHeaderPhone" href="tel:1-800-381-0237">
              1-800-381-0237
            </a>
          </div>
        </div>
        <div className="row justify-content-center pt-50">
          <div className="col-4 col-lg-3">
            <div className="footerLinks">
              <Link to="/">
                <h5 className="footerHeaders">HOME</h5>
              </Link>
              <Link to="/about">About Us</Link>
              <Link to="/our-mission">Our Mission</Link>
              <Link to="/contact">Contact Us</Link>
              <a
                href="https://atlantissubmarines.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Global Site
              </a>
              <Link to="/travel-agent">Travel Agent Portal</Link>
              <Link to="/employment">Employment</Link>
              <Link to="/kamaaina">Kama'aina</Link>
              <Link to="/narration">Narration Download</Link>
            </div>
          </div>
          <div className="col-4 col-lg-3">
            <div className="footerLinks">
              <Link to="/submarines">
                <h5 className="footerHeaders">ATLANTIS SUBMARINES</h5>
              </Link>
              <Link to="/waikiki">Waikiki Submarine</Link>
              <Link to="/maui">Maui Submarine</Link>
              <Link to="/kona">Kona Submarine</Link>
            </div>
          </div>
          <div className="col-4 col-lg-3">
            <div className="footerLinks">
              <a
                href="https://majestichawaii.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h5 className="footerHeaders">MAJESTIC BY ATLANTIS CRUISES</h5>
              </a>
              <a href="https://atlantisadventures.rezgo.com/details/204130/waikiki-sunset-cocktail-cruise">
                Waikiki Sunset Cocktail Cruise
              </a>
              <a href="https://atlantisadventures.rezgo.com/details/205347/friday-fireworks-cocktail-cruise">
                Friday Fireworks & Cocktail Cruise
              </a>
              <a href="https://atlantisadventures.rezgo.com/details/154748/whale-watch-cruise-in-waikiki">
                Whale Watch Cruise in Waikiki
              </a>
            </div>
          </div>
        </div>
        <hr />
        <div className="row social justify-content-center center">
          <div className="col-5 col-lg-3">
            <a
              href="https://www.facebook.com/AtlantisHawaii/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook />
            </a>
            <a
              href="https://www.instagram.com/atlantishawaii/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
          </div>
          <div className="col-5 col-lg-3">
            <a
              href="https://www.sustainabletourismhawaii.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="heaLogo" src={hea} alt="hea logo" />
            </a>
          </div>
        </div>
        <hr />
        <div className="row copyright justify-content-center">
          <div className="col-12 col-md-6 col-lg-3">
            <p style={{ color: "#fff" }}>
              Copyright © 2023 Atlantis Adventures LLC.
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <Link to="/privacy">Privacy Policy</Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
