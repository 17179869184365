import top from "../images/travelAgentTop.jpg";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Stack from "react-bootstrap/Stack";
import { Helmet } from "react-helmet";

function Travel() {
  return (
    <>
      <Helmet>
        <title>Atlantis Submarine Adventures | Travel Agent</title>
      </Helmet>
      <div className="whiteBG travel pb-50">
        <img
          src={top}
          style={{ width: "100%", paddingBottom: "50px" }}
          alt="travel bg"
        />
        <div className="travelVertical">
          <div className="container">
            <div className="row justify-content-evenly center">
              <div className="col-12 col-md-5 col-lg-4 pb-50">
                <h3 style={{ color: "#003366", fontWeight: "bold" }}>
                  SALES SUPPORT
                </h3>
                <Stack gap={3}>
                  <Button as={Link} to="/sales-packets" variant="primary">
                    SALES PACKETS
                    <span
                      style={{
                        display: "block",
                        fontSize: "12px",
                        fontWeight: "normal",
                      }}
                    >
                      (FACT SHEETS, IMAGES, VIDEOS)
                    </span>
                  </Button>
                  <Button as={Link} to="/agency" variant="primary">
                    ATLANTIS ADVENTURES TOUR HIGHLIGHTS
                  </Button>
                </Stack>
              </div>
              <div className="col-12 col-md-5 col-lg-4">
                <h3 style={{ color: "#003366", fontWeight: "bold" }}>
                  LIVE INVENTORY & BOOKING
                </h3>
                <Stack gap={3}>
                  <Button href="https://atlantisadventures.rezgo.com/details/151037/atlantis-submarine-tour-maui/?promo=travelagent">
                    MAUI SUBMARINES
                  </Button>
                  <Button href="https://atlantisadventures.rezgo.com/details/154563/atlantis-submarine-tour-kona/?promo=travelagent">
                    KONA SUBMARINES
                  </Button>
                  <Button href="https://atlantisadventures.rezgo.com/details/151625/atlantis-submarine-tour-in-waikiki/?promo=travelagent">
                    OAHU SUBMARINES
                  </Button>
                  <Button href="https://atlantisadventures.rezgo.com/details/204130/waikiki-sunset-cocktail-cruise/?promo=travelagent">
                    OAHU MAJESTIC CRUISES
                  </Button>
                </Stack>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Travel;
